import React from "react"

export default function Author({ avatar, title, name }) {
  return (
    <div className="flex flex-row flex-wrap items-center">
      {avatar}
      <div>
        <h4 className="text-gray-900 font-medium">{name}</h4>
        <p className="text-gray-500">{title}</p>
      </div>
    </div>
  )
}
